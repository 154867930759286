import { faker } from '@faker-js/faker/locale/ja';
import { HttpResponse, delay, http } from 'msw';
faker.seed();
// const reportFormType = {
//   ApReportFormInputCheckBox: 'ApReportFormInputCheckBox',
//   ApReportFormInputInvoiceInstantPayee: 'ApReportFormInputInvoiceInstantPayee',
//   ApReportFormInputReportNumberForExpenseField:
//     'ApReportFormInputReportNumberForExpenseField',
//   ApReportFormInputContractNumberField: 'ApReportFormInputContractNumberField',
//   ApReportFormInputInvoiceRegistrationNumberField:
//     'ApReportFormInputInvoiceRegistrationNumberField',
//   ApReportFormInputSelect: 'ApReportFormInputSelect',
//   ApReportFormInputDateField: 'ApReportFormInputDateField',
//   ApReportFormInputNumberField: 'ApReportFormInputNumberField',
//   ApReportFormInputSpecialExceptionStatusSelect:
//     'ApReportFormInputSpecialExceptionStatusSelect',
//   ReportFormStatusContainer: 'ReportFormStatusContainer',
//   ApReportFormInputDeptSelect: 'ApReportFormInputDeptSelect',
//   ApReportFormInputNumberFieldForBranch:
//     'ApReportFormInputNumberFieldForBranch',
//   ApReportFormInputTextArea: 'ApReportFormInputTextArea',
//   ApReportFormInputExItemSelect: 'ApReportFormInputExItemSelect',
//   ApReportFormInputPayeeSelect: 'ApReportFormInputPayeeSelect',
//   ApReportFormInputTextField: 'ApReportFormInputTextField',
//   ApReportFormInputFileField: 'ApReportFormInputFileField',
//   ApReportFormInputProjectSelect: 'ApReportFormInputProjectSelect',
//   ApReportFormInputTimeField: 'ApReportFormInputTimeField',
//   ApReportFormInputInvoiceBookDateField:
//     'ApReportFormInputInvoiceBookDateField',
//   ApReportFormInputRadioButton: 'ApReportFormInputRadioButton',
//   ApReportFormInputInvoiceDueDateField: 'ApReportFormInputInvoiceDueDateField',
//   ApReportFormInputReceiptType: 'ApReportFormInputReceiptType',
// };

// const reportFormInputsData = [
//   {
//     annotation: ['test', 'test1'],
//     default_value: ['test'],
//     has_other_value: false,
//     id: faker.word.sample(),
//     input_options: getGetApPayees(1),
//     input_values: undefined,
//     is_editable: faker.datatype.boolean(), // for setting, it is unused at edit page
//     is_hidden: faker.datatype.boolean(), // for setting, it is unused at edit page
//     is_required: true,
//     label: '支払先',
//     priority: faker.number.int({ min: undefined, max: undefined }),
//     type: reportFormType.ApReportFormInputPayeeSelect,
//   },
// ];

export const getGetInvoiceReportsMock = () => ({
  id: 'M9X9hw-4lnmdpOhMJPGn_g',
  title: '2024年10月支払依頼',
  report_dept: {
    dept_and_position_name: '部門なし',
    fixed_dept_and_position_name: '部門なし',
    initial_selected_id: '0',
    is_changeable: true,
    is_viewable: true,
  },
  status: 'unsubmitted',
  number: 'AP1138',
  number_without_prefix: 1138,
  submitted_at: null,
  approved_at: null,
  created_at: '2024-10-28T18:51:37+09:00',
  updated_at: '2024-11-08T19:46:39+09:00',
  flash_messages: [],
  report_type: {
    id: '051Dq7-RWhIVs_J9bz2LzA',
    name: '支払依頼',
  },
  report_form_inputs: [
    {
      id: '8WxGG2OVVPztq9cx2RTmag',
      label: '支払先種別',
      type: 'ApReportFormInputInvoicePayeeType',
      priority: 0,
      is_required: true,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: '1005',
      annotation: '',
      input_options: [
        {
          id: 'LhmqtGuYLBR_m2jWnlaisg',
          ap_report_form_input_option_master_id: 1005,
          value: '登録済みの支払先',
          display_value: '登録済みの支払先',
          priority: 1,
        },
        {
          id: 'MslzAzJ1-FPqru_foi2Q6A',
          ap_report_form_input_option_master_id: 1006,
          value: 'スポット支払（未登録の支払先）',
          display_value: 'スポット支払（未登録の支払先）',
          priority: 2,
        },
      ],
      input_values: [
        {
          id: '2AVRqUi1qzcK-QudXDxBdg',
          value: '1005',
        },
      ],
      input_additional_values: null,
    },
    {
      id: '9vcyxeq0tB20bQFYcoaraw',
      label: '支払先',
      type: 'ApReportFormInputInvoicePayeeSelect',
      priority: 1,
      is_required: true,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: null,
      annotation: '支払先が無い場合は管理者に登録を依頼してください',
      input_options: null,
      input_values: [
        {
          id: 'xRCMFchmk6xj0ClVM6mdpg',
          value: 'aCkOVet_cb2z-uLXgVQ0YQ',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'o6vpshKaWxSRSoo-L_2Qcw',
      label: '支払先(スポット支払)',
      type: 'ApReportFormInputInvoiceInstantPayee',
      priority: 2,
      is_required: true,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: null,
      input_additional_values: null,
    },
    {
      id: 'JR8vsZGAYeOLufXBiqjPxg',
      label: '特例区分',
      type: 'ApReportFormInputSpecialExceptionStatus',
      priority: 3,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: '23iLnaVz7Ly4vPZZluHBhA',
          value: '0',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'JomjIKEPZMvTLJ78PxvmZA',
      label: '適格請求書発行事業者登録番号',
      type: 'ApReportFormInputInvoiceRegistrationNumberField',
      priority: 4,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'QNSLieiIGl5NQQsXjd0z5A',
          value: '',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'p67_FhRVTzphnfBVUYBeWA',
      label: '請求書画像',
      type: 'ApReportFormInputInvoiceFileField',
      priority: 5,
      is_required: false,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'C3FjO9L_kCENSm7vGCfpPg',
          value: 'prEqTv8Obt9YRNG7oCGtfw',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'bQS7rRgT6xc8K53bexhJXg',
      label: '請求書の受領区分',
      type: 'ApReportFormInputReceiptType',
      priority: 6,
      is_required: false,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: '',
      annotation: '請求書画像が添付された時のみ、必須項目になります',
      input_options: [
        {
          id: 'yAsHYR97lSzYEJdRgenShg',
          ap_report_form_input_option_master_id: 1003,
          value: '1003',
          display_value: '電子 (PDF,JPEG等) で受領した請求書',
          priority: 1,
        },
        {
          id: 'CDKbx9n3NOWGohCowI038A',
          ap_report_form_input_option_master_id: 1004,
          value: '1004',
          display_value: '紙で受領した請求書',
          priority: 2,
        },
      ],
      input_values: [
        {
          id: 'upJF0NbJWM26tApSEMBfzw',
          value: '1003',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'MF0QySkZ4YmWUFqJ9RP1eA',
      label: '取引関係書類',
      type: 'ApReportFormInputBusinessDocumentsField',
      priority: 7,
      is_required: false,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'UUybTANP4SbzokldLUJIrA',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: '9R6VsBnZrDmDBN3CPLPYeA',
      label: '支払期日',
      type: 'ApReportFormInputInvoiceDueDateField',
      priority: 8,
      is_required: true,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: '4MMVftHsZY3BWT3-aNTxzw',
          value: '2024/11/11',
        },
      ],
      input_additional_values: null,
    },
    {
      id: '6RIKWrgVhQZ28JThMe_9NQ',
      label: '費用計上日',
      type: 'ApReportFormInputInvoiceBookDateField',
      priority: 9,
      is_required: true,
      is_hidden: false,
      is_editable: false,
      has_other_value: false,
      default_value: null,
      annotation: 'デフォルトは前月末日付となります',
      input_options: null,
      input_values: [
        {
          id: 'VufJ3vOpPGE5QtC5SqyKeQ',
          value: '2024/10/20',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'b4fTGfrwQGfPWhbDBzAykQ',
      label: '目的',
      type: 'ApReportFormInputTextArea',
      priority: 10,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '目的を記載してください',
      input_options: null,
      input_values: [
        {
          id: 'fhM7R0JerLLCwk6LLpVZig',
          value: '',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'ah-kDYBhmKWjToOlhXuJAA',
      label: '申請番号',
      type: 'ApReportFormInputReportNumberForExpenseField',
      priority: 11,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'kFPYQ7Q7dpI_TOSlPZpTWQ',
          value: '',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'SdYMtsqwfrLtq0xV-E8ezQ',
      label: '非表示項目',
      type: 'ApReportFormInputTextField',
      priority: 12,
      is_required: false,
      is_hidden: true,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: null,
      input_additional_values: null,
    },
    {
      id: 'sBPvKhFm9HGUB0IZkXcXKw',
      label: '選択肢',
      type: 'ApReportFormInputCheckBox',
      priority: 13,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: true,
      default_value: '',
      annotation: '',
      input_options: [
        {
          id: 'GhhMsLm2MTGMcqCzkGdsoQ',
          ap_report_form_input_option_master_id: null,
          value: '11',
          display_value: '11',
          priority: 1,
        },
        {
          id: 'rmlBbYYDyVjYE3fCaREhtA',
          ap_report_form_input_option_master_id: null,
          value: '22',
          display_value: '22',
          priority: 1,
        },
        {
          id: 'Y-WQ-_zjGeoLhUHpO4NdyQ',
          ap_report_form_input_option_master_id: null,
          value: '33',
          display_value: '33',
          priority: 1,
        },
      ],
      input_values: [
        {
          id: 'WlgloIQLVAo54FzUS0brfQ',
          value: '',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'ks4_UunenAVKw1Vm_TKB6g',
      label: '数値',
      type: 'ApReportFormInputNumberField',
      priority: 14,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: '9HQrOBvbWK9RCHEVZBsXvg',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'ZQBWOnqUD19N25-UosNO_Q',
      label: '添付ファイル',
      type: 'ApReportFormInputFileField',
      priority: 15,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'inHLEh6vmXY62LVG6zCW9w',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'iDvFkygkW_CaN7tx58JAmQ',
      label: '費用科目',
      type: 'ApReportFormInputProjectSelect',
      priority: 16,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'IEYzRcZn6MxWLYWX20sOeg',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'FbnNuV6cpxl2AtyDY40aOA',
      label: '分岐条件利用',
      type: 'ApReportFormInputNumberFieldForBranch',
      priority: 17,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '3',
      annotation: '分岐条件利用',
      input_options: null,
      input_values: [
        {
          id: 'NG2JwK_8t5O3UF3P2lOWlA',
          value: '3',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'AXpcaqHyUugrURIhGSFcAA',
      label: '日時',
      type: 'ApReportFormInputTimeField',
      priority: 18,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '日付',
      input_options: null,
      input_values: [
        {
          id: 'l9ehnDl5MQJquvc0Ais9Vg',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: '1AIFNo2uFG6wq_ypgaihZg',
      label: 'OfficeMember',
      type: 'ApReportFormInputOfficeMemberSelect',
      priority: 19,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'OXVN3LxmzudJNkfzDvr7pw',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'MJ5GxVToep0Qbas-OSqrIQ',
      label: '部門',
      type: 'ApReportFormInputDeptSelect',
      priority: 20,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: '0pHV7llhYTKczxWw_kiKqQ',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'WHiaLkRiLQGw5F5b9g75QQ',
      label: 'pj',
      type: 'ApReportFormInputProjectSelect',
      priority: 21,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: '-uYRUElK5als5OSr6ZuCdQ',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'Wb9wZt-QdZF-QDyqRiF1JQ',
      label: 'exItem',
      type: 'ApReportFormInputExItemSelect',
      priority: 22,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: null,
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'SpH28M8VkYbZURQDxZaznA',
          value: null,
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'k7qISGQSFL0Kv-WkWGCu4g',
      label: 'aaa',
      type: 'ApReportFormInputSelect',
      priority: 23,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: true,
      default_value: '',
      annotation: '',
      input_options: [
        {
          id: 'r9rnJ6ZsDW_reYhcbJqXUg',
          ap_report_form_input_option_master_id: null,
          value: '1',
          display_value: '1',
          priority: 0,
        },
        {
          id: 'Ex5y6xzZLpbvGwbIYZs35g',
          ap_report_form_input_option_master_id: null,
          value: '2',
          display_value: '2',
          priority: 1,
        },
        {
          id: 'voJkxpzIqvUyay1rRjLmiA',
          ap_report_form_input_option_master_id: null,
          value: '3',
          display_value: '3',
          priority: 2,
        },
      ],
      input_values: [
        {
          id: 'COw84ZTr3whUYEQHgZNjYA',
          value: 'aiueo',
        },
      ],
      input_additional_values: null,
    },
    {
      id: '9Ygx3QBkyIALey3UrEWmhg',
      label: '事前申請番号',
      type: 'ApReportFormInputReportNumberForExpenseField',
      priority: 24,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: 'aXN-rRTGQN1NEvgGYOtuOQ',
          value: '',
        },
      ],
      input_additional_values: null,
    },
    {
      id: 'GM8eoUcbAiAvSRjaRgrXqA',
      label: '契約番号',
      type: 'ApReportFormInputContractNumberField',
      priority: 25,
      is_required: false,
      is_hidden: false,
      is_editable: true,
      has_other_value: false,
      default_value: '',
      annotation: '',
      input_options: null,
      input_values: [
        {
          id: '4sIcv6_K1HIsrKoVgLCLLA',
          value: '',
        },
      ],
      input_additional_values: null,
    },
  ],
});

export const handlers = [
  http.get('*/api/js/invoice_reports/:id', async () => {
    await delay(1000);
    return new HttpResponse(JSON.stringify(getGetInvoiceReportsMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
