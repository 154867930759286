/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { faker } from '@faker-js/faker';
import { OfficeMemberPositionDept } from 'ap-openapi';
import { HttpResponse, delay, http } from 'msw';

export const getGetOfficeMemberPositionDeptsMock: () => {
  office_member_position_depts: OfficeMemberPositionDept[];
} = () => ({
  office_member_position_depts: [
    {
      dept: {
        id: '0',
        name: '部門なし',
      },
      id: 'xxxxxxxxxxxxxxx',
      position: {
        id: faker.word.sample(),
        name: 'xxxx',
      },
    },
    {
      dept: {
        id: '1',
        name: 'test_depts_1',
      },
      id: '1',
      position: {
        id: faker.word.sample(),
        name: 'test_position_1',
      },
    },
    {
      dept: {
        id: '2',
        name: 'test_depts_2',
      },
      id: '2',
      position: {
        id: faker.word.sample(),
        name: 'test_position_2',
      },
    },
    {
      dept: {
        id: '3',
        name: 'test_depts_3',
      },
      id: '3',
      position: {
        id: faker.word.sample(),
        name: 'test_position_3',
      },
    },
  ],
});

export const handlers = [
  http.get(
    '*/api/js/office_members/:officeMemberId/position_depts',
    async () => {
      await delay(1000);
      return new HttpResponse(
        JSON.stringify(getGetOfficeMemberPositionDeptsMock()),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
];
