import { getOfficeMembers } from '@/libs/mocks/handlers/ap-payee';
import { faker } from '@faker-js/faker/locale/ja';
import type {
  ApReportStatus as TApReportStatus,
  UpdateReceivedInvoiceRequest,
} from 'ap-openapi';
import {
  ApReport,
  ApReportStatus,
  ReceivedInvoiceDetail,
  ReceivedInvoiceOperationStatus,
  ReceivedInvoiceReceiptType,
  ReceivedInvoiceUploadType,
  SearchReceivedInvoicesRequest,
} from 'ap-openapi';
import { toISODateString } from 'date-util';
import dayjs from 'dayjs';
import { HttpResponse, delay, http } from 'msw';

faker.seed(1);

const getReceivedInvoice = (i: number): ReceivedInvoiceDetail => {
  const apReportStatus: TApReportStatus =
    {
      0: ApReportStatus.unsubmitted,
      1: ApReportStatus.unsubmitted,
      2: ApReportStatus.waiting_step_one,
      3: ApReportStatus.unsubmitted,
      4: ApReportStatus.unsubmitted,
    }[i] ||
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (faker.helpers.weightedArrayElement(
      Object.keys(ApReportStatus).map((v) => ({
        weight: v === ApReportStatus.unsubmitted ? 10 : 2,
        value: v,
      }))
    ) as TApReportStatus);

  const invoiceReport: ApReport | null =
    apReportStatus === ApReportStatus.unsubmitted
      ? null
      : {
          office_member: (() => {
            const id = faker.string.uuid();
            const name = faker.person.fullName();
            return {
              id: id,
              name: name,
              display_name: `${name}(${id})`,
            };
          })(),
          status: apReportStatus,
          searchable_number: `AP${faker.number.int({ min: 1, max: 3 })}`,
        };
  const files = faker.helpers.arrayElement([
    `請求書${faker.number.int({
      min: 1,
      max: 2,
    })}.pdf`,
    'image.jpg',
  ]);
  return {
    ap_payee: {
      code: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      id: faker.string.uuid(),
      name: faker.company.name(),
    },
    filename: files,
    id: `${i + 1}`,
    memo:
      i % 2 === 0
        ? faker.word.sample({ length: { min: 100, max: 2000 } })
        : undefined,
    operation_status:
      {
        0: ReceivedInvoiceOperationStatus.not_started,
        1: ReceivedInvoiceOperationStatus.applying,
        2: ReceivedInvoiceOperationStatus.applied,
        3: ReceivedInvoiceOperationStatus.archived,
        4: ReceivedInvoiceOperationStatus.ocr_processing,
      }[i] ||
      faker.helpers.arrayElement(Object.values(ReceivedInvoiceOperationStatus)),
    pics: getOfficeMembers(0)?.filter(
      (_, i) => i <= faker.number.int({ min: 1, max: 5 })
    ),
    searchable_number: i + 1,
    upload_type: faker.helpers.arrayElement(
      Object.values(ReceivedInvoiceUploadType)
    ),
    uploaded_at: `${faker.date.past().toISOString().split('.')[0]}Z`,
    uploader: {
      id: faker.word.sample(),
      name: faker.word.sample(),
    },
    mf_file: {
      id: files,
      name: faker.string.alpha({ length: { max: 15, min: 1 } }),
      e_doc_meta_datum: {
        is_scan: faker.datatype.boolean(),
        is_satisfy_resolution_requirement: faker.helpers.arrayElement([
          faker.datatype.boolean(),
        ]),
        is_satisfy_depth_requirements: faker.helpers.arrayElement([
          faker.datatype.boolean(),
        ]),
        dpi_text: `${faker.number.int({ min: 30, max: 600 })} dpi`,
        pixel_number_text: `${faker.number.int({ min: 1, max: 50 })}万画素`,
        width_and_height_pixel_text: `${faker.number.int({
          min: 800,
          max: 4000,
        })} * ${faker.number.int({ min: 600, max: 3000 })}`,
        rgb_depth_text: `RGB(${faker.number.int({
          min: 100,
          max: 256,
        })}, ${faker.number.int({ min: 100, max: 256 })}, ${faker.number.int({
          min: 100,
          max: 256,
        })})`,
      },
    },
    receipt_type: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(ReceivedInvoiceReceiptType)),
      undefined,
    ]),
    due_date: faker.helpers.arrayElement([
      toISODateString(dayjs(faker.date.anytime())),
      undefined,
    ]),
    ocr_payee_name: faker.helpers.arrayElement([
      faker.company.name(),
      undefined,
    ]),
    total_amount: faker.helpers.arrayElement([
      faker.finance.amount(),
      undefined,
    ]),
    invoice_report: invoiceReport,
    registration_number: `${Array.from({ length: 13 })
      .map(() => faker.number.int({ max: 9 }))
      .join('')}`,
  };
};

const getReceivedInvoicesSearchMock = (page = 1, per_page = 25) => {
  return {
    pagination: {
      has_next_page: true,
      has_previous_page: true,
      page: page,
      per_page: per_page,
      total_pages: 100,
      total_records: 1000,
    },
    received_invoices: faker.helpers.arrayElement([
      Array.from({ length: per_page }, (_, i) => i + 1).map(
        (_, i) => getReceivedInvoice(i),
        undefined
      ),
    ]),
  };
};

export const getGetSignedXmlFileUrlMock = () => ({
  signed_url: '/invoices/sample.xml',
});

export const handlers = [
  http.post<never, SearchReceivedInvoicesRequest, never>(
    '*/api/js/received_invoice/v1/received_invoices/search',
    async ({ request }) => {
      await delay(1000);
      const reqBody = await request.json();

      return new HttpResponse(
        JSON.stringify(
          getReceivedInvoicesSearchMock(
            reqBody.pagination?.page,
            reqBody.pagination?.per_page
          )
        ),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
  http.get(
    '*/api/js/received_invoice/v1/received_invoices/:id',
    async (req) => {
      await delay(1000);
      const { params } = req;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const id = parseInt(params['id'] as string);
      if (Number.isNaN(id)) {
        return new HttpResponse(
          JSON.stringify({
            code: 'not_found',
            messages: ['not found'],
          }),
          {
            status: 404,
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      }

      return new HttpResponse(JSON.stringify(getReceivedInvoice(id - 1)), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
  http.post(
    '*/api/js/received_invoice/v1/received_invoices/:id/archive',
    async () => {
      await delay(1000);
      return new HttpResponse(null, {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
  http.post(
    '*/api/js/received_invoice/v1/received_invoices/:id/restoration',
    async () => {
      await delay(1000);
      return new HttpResponse(null, {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
  http.patch<never, UpdateReceivedInvoiceRequest, never>(
    '*/api/js/received_invoice/v1/received_invoices/:id',
    async ({ params, request }) => {
      await delay(1000);
      const isSuccess = Math.random() % 2 === 0;
      if (!isSuccess) {
        const body = {
          errors: {
            status: [
              {
                code: 'not_allowed',
                message: '請求書スターテスはデータを更新できません。',
              },
            ],
          },
        };
        return new HttpResponse(JSON.stringify(body), {
          status: 422,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const id = (params['id'] as unknown as number) - 1;
      const data = getReceivedInvoice(id);
      const reqBody = await request.json();
      data.memo = reqBody.received_invoice.memo;
      data.pics = reqBody.received_invoice.pic_ids?.map((item) => ({
        id: item,
        name: faker.person.fullName(),
      }));
      data.receipt_type = reqBody.received_invoice.receipt_type;

      return new HttpResponse(JSON.stringify(data), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
  http.get(
    '*/api/js/received_invoice/v1/received_invoices/:id/preview_xml_url',
    async () => {
      await delay(1000);
      return new HttpResponse(JSON.stringify(getGetSignedXmlFileUrlMock()), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
];
